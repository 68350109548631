// import { CardContent, Card } from "@/components/ui/card"
import { Link } from "react-router-dom";
import { Card, CardContent, ComingSoonCard, Footer } from "../../components";
import MondayComHero from '../../assets/MondayImage.png';
import GoogleSyncLogo from '../../assets/googleSync.jpeg';

export const MondayIndex = ():JSX.Element => {
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <main className="flex-1">
        <section className="w-full pt-12 md:pt-24 lg:pt-32 border-y">
          <div className="px-4 md:px-6 space-y-10 xl:space-y-16">
            <div className="grid max-w-[1300px] mx-auto gap-4 px-4 sm:px-6 md:px-10 md:grid-cols-2 md:gap-16">
              <div>
                <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
                  For teams that want to work better
                </h1>
              </div>
              <div className="flex flex-col items-start space-y-4 mt-8">
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
                  Using Monday.com? Want to enjoy it more? Want to work better, faster, stronger? Bumped into something for us to solve? 
                  Contact us and we will give free year if we end up building it!
                </p>
              </div>
            </div>
            <img
              alt="Hero"
              className="mx-auto aspect-[3/1] overflow-hidden rounded-t-xl object-cover"
              height="200"
              src={MondayComHero}
              width="50%"
            />
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter mb-8 sm:text-4xl">Our Projects</h2>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <Link className="group" to="#">
                <Card>
                  <img
                    alt="Smart Tracker"
                    className="aspect-video overflow-hidden rounded-t-xl object-cover object-center"
                    height="310"
                    src="/placeholder.svg"
                    width="550"
                  />
                  <CardContent className="p-4">
                    <h3 className="text-lg font-bold group-hover:underline">Smart Teams Time Tracker</h3>
                    <p className="text-gray-500 dark:text-gray-400">Now teams can track their times in mobile, faster and with ease!</p>
                  </CardContent>
                </Card>
              </Link>
              <Link className="group" to="/monday/google-sync">
                <Card>
                  <img
                    alt="Google Sync"
                    className="aspect-video overflow-hidden rounded-t-xl object-cover object-center"
                    height="310"
                    src={GoogleSyncLogo}
                    width="550"
                  />
                  <CardContent className="p-4">
                    <h3 className="text-lg font-bold group-hover:underline">Sync your google contacts with Monday.com board</h3>
                    <p className="text-gray-500 dark:text-gray-400">Enjoy seamless sync from your Google Contacts to your leads board</p>
                  </CardContent>
                </Card>
              </Link>
              <ComingSoonCard />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}